/*
 * @Author: yanbao dong
 * @Date: 2022-08-26 17:10:37
 * @LastEditors: yanbao dong
 * @LastEditTime: 2022-09-07 09:43:10
 * @Description: file content
 */
import React from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import ScrollContainer from 'react-indiana-drag-scroll';
import Option from './Option';
const InvestmentOptions = ({ doNotShow = '' }) => {
  return (
    <div>
      {/* 【产品详情一级入口卡片】- WEB端 */}
      <div
        css={css`
          display: none;
          ${Desktop} {
            display: flex;
            padding-left: 13px;
            // margin-top: -232px;
            // width: 1200px;
            // justify-content: space-between;
            // margin-top: 50px;
          }
        `}
      >
        <Option doNotShow={doNotShow} name='global' />
        {/* <Option doNotShow={doNotShow} name='SmartStock' /> */}
        {/* <Option doNotShow={doNotShow} name='StockTrading' /> */}
        <Option doNotShow={doNotShow} name='unique' />
        <Option doNotShow={doNotShow} name='custom' />
      </div>
      {/* 【产品详情一级入口卡片】- IAPD端、MOBILE端 */}
      <ScrollContainer
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 10px 5px 10px 20px;
          ${Tablet} {
            padding: 10px 10px 10px 30px;
          }
          ${Desktop} {
            display: none;
          }
        `}
      >
        <Option doNotShow={doNotShow} name='global' />
        {/* <Option doNotShow={doNotShow} name='SmartStock' /> */}
        {/* <Option doNotShow={doNotShow} name='StockTrading' /> */}
        <Option doNotShow={doNotShow} name='unique' />
        <Option doNotShow={doNotShow} name='custom' />
      </ScrollContainer>
    </div>
  );
};

export default InvestmentOptions;
