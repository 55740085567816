import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Link, useIntl } from 'gatsby-plugin-intl';
const Option = ({ name = 'LongTerm', doNotShow = '', url }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      global: file(relativePath: { eq: "homepage/home-invest/global.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      unique: file(relativePath: { eq: "homepage/home-invest/unique.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      custom: file(relativePath: { eq: "homepage/home-invest/custom.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  console.log(data, 'useStaticQuery');
  return (
    <div
      css={css`
        display: ${name === doNotShow ? 'none' : 'block'};
        flex: 0 0 auto;
        width: 220px;
        height: 220px;
        border-radius: 15px;
        :last-child {
          margin-right: 0;
        }
        ${Tablet} {
          width: 286px;
          height: 286px;
        }
        ${Desktop} {
          width: 382px;
          height: 286px;
        }
      `}
    >
      <div
        css={css`
          // display: ${name === doNotShow ? 'none' : 'block'};
          flex: 0 0 auto;
          width: 220px;
          height: 220px;
          border-radius: 15px
          margin-right:10px;
          :last-child {
            margin-right: 0;
          }
          position: relative;
          background: ${`url(${data[name].childImageSharp.fluid.src})`}
            center 100% no-repeat ;
            background-size: 100% 100%;
          ${Tablet} {
            width: 286px;
            height: 286px;
            margin-right: 20px;
            :last-child {
              margin-right: 0;
            }
            position: relative;
            background: ${`url(${data[name].childImageSharp.fluid.src})`}
              center;
              background-size: 100% 100%;
          }
          ${Desktop} {
            width: 382px;
            height: 286px;
            margin-right: 20px;
            :last-child {
              margin-right: 0;
            }
            position: relative;
            background: ${`url(${data[name].childImageSharp.fluid.src})`}
              center;
              background-size: 100% 100%;
          }
        `}
      >
        <div
          css={css`
            font-size: 14px;
            font-weight: 500;
            line-height: 1.29;
            color: #f8f8f8;
            margin-left: 34px;
            padding-top: 45px;
            white-space: pre-wrap;
            ${Tablet} {
              font-size: 20px;
              font-weight: 500;
              line-height: 1.33;
            }
            ${Desktop} {
              font-size: 20px;
              font-weight: 500;
              line-height: 1.33;
            }
          `}
        >
          {intl.formatMessage({
            id: `individuals.investment-options.${name}.title`,
          })}
        </div>
      </div>
    </div>
  );
};

export default Option;
